export default {
    name: 'Finder',

    components: {},
    data() {
        return {
            books: [],
            search: '',
            select: [],
            category_elements: [],
            author_elements: [],
            format_elements: [{
                name: "EPUB",
                id: "epub"
            }, {
                name: "PDF",
                id: "pdf"
            }],
            selected_category: [],
            selected_author: "",
            selected_format: "",
            spinner: false,
            filtrosShow: false
        }
    },
    beforeMount() {
        this.$store.dispatch('setSpinner', true);
    },
    async mounted() {
        try {
            await Promise.all([
                this.$store.dispatch("fetchAllBooks"),
                this.$store.dispatch("fetchAllCategories"),
                this.$store.dispatch("fetchAllAuthors")
            ]);
            let booksList = this.$store.getters.getAllBooks;
            this.category_elements = this.$store.getters.getAllCategories;
            this.author_elements = this.$store.getters.getAllAuthors;

            booksList.map(b => {
                b.formatString = "";
                b.formats.map((f, index) => {
                    b.formatString += index == 0 ? "FORMATO " + f.type.toUpperCase() : ", " + f.type.toUpperCase();
                });
                this.books.push(b);
            })

            if (localStorage['filter_search'] && localStorage['filter_search'].length) {
                this.search = JSON.parse(localStorage['filter_search']);
                this.filtrosShow = true;
            }
            if (localStorage['filter_category'] && localStorage['filter_category'].length) {
                this.selected_category = JSON.parse(localStorage['filter_category']);
                this.filtrosShow = true;
            }
            if (localStorage['filter_author'] && localStorage['filter_author'].length) {
                this.selected_author = JSON.parse(localStorage['filter_author']);
                this.filtrosShow = true;
            }
            if (localStorage['filter_format'] && localStorage['filter_format'].length) {
                this.selected_format = JSON.parse(localStorage['filter_format']);
                this.filtrosShow = true;
            }

        } catch (error) {
            console.log(error);
            alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
        } finally {
            this.$store.dispatch('setSpinner', false);
        }
    },
    computed: {
        booksFiltered() {
            let booksToReturn = this.books;
            if (this.search) {
                localStorage['filter_search'] = JSON.stringify(this.search);
                booksToReturn = booksToReturn.filter(book => {
                    return (book.title.toLowerCase().includes(this.search.toLowerCase()) ||
                        book.author_sort.toLowerCase().includes(this.search.toLowerCase()));
                })
            }
            if (this.selected_category && this.selected_category.length) {
                localStorage['filter_category'] = JSON.stringify(this.selected_category);
                let categories_names = this.selected_category.map(c => c.name);
                booksToReturn = booksToReturn.filter(book => book.tag.find(t => categories_names.includes(t.name)));
            }
            if (this.selected_author && this.selected_author.name && this.selected_author.name != "") {
                localStorage['filter_author'] = JSON.stringify(this.selected_author);
                booksToReturn = booksToReturn.filter(book => {
                    return book.author_sort == this.selected_author.sort
                });
            }
            if (this.selected_format && this.selected_format.name && this.selected_format.name != "") {
                localStorage['filter_format'] = JSON.stringify(this.selected_format);
                booksToReturn = booksToReturn.filter(book => book.formats.find(f => f.type == this.selected_format.name));
            }

            return booksToReturn;
        },
        booksFilteredSpecificSelection() {
            return
        },
        selectionOptions() {
            return this.select;
        }
    },
    methods: {
        format_author(author) {
            if (author.includes(',')) {
                return author.split(", ").reverse().join(" ");
            } else {
                return author;
            }
        },
        clearSearch() {
            this.search = "";
        },
        openBook(book) {
            this.$router.push({
                name: "Book",
                params: {
                    book: book,
                    bookID: book.id
                }
            })
        },
        checkEmptyInput(input) {
            switch (input) {
                case 'search':
                    if (!this.search) {
                        localStorage['filter_search'] = "";
                    }
                    break;
                case 'category':
                    if (!this.selected_category) {
                        localStorage['filter_category'] = "";
                    }
                    break;
                case 'author':
                    if (!this.selected_author) {
                        localStorage['filter_author'] = "";
                    }
                    break;
                case 'format':
                    if (!this.selected_format) {
                        localStorage['filter_format'] = "";
                    }
                    break;
            }
        },
        emptySearchInputs() {
            this.search = '';
            this.selected_category = "";
            this.selected_author = "";
            this.selected_format = "";
            localStorage['filter_search'] = JSON.stringify(this.search);
            localStorage['filter_category'] = JSON.stringify(this.selected_category);
            localStorage['filter_author'] = JSON.stringify(this.selected_author);
            localStorage['filter_format'] = JSON.stringify(this.selected_format);
        }
    }
}